@import url('https://fonts.googleapis.com/css2?family=Racing+Sans+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sansita&display=swap');


.contenedor2{
    width: 100%;
    height: 150%;
	background: linear-gradient(1deg,#003c6a,#03A9F4);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
}

.cartas__textos h2{
    font-family: 'Francois One', sans-serif;
    margin-top: 10px;
    text-shadow: 2px 2px rgb(255, 255, 255);
    color: black;
}

.sucursales{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding-bottom: 50px;
}

.sucursales__fotos{
    display: flex;
    box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.882);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    border:3px dashed rgb(0, 0, 0);
    background-color: rgba(255, 255, 255, 0.701);
    border-radius: 20px;
    color: black;
}

.sucursales__fotos h1{
    text-align: center;
    font-size: 25px;
    font-family: 'Sansita', sans-serif;
}

.sucursales__fotos p{
    text-align: center;
    font-size: 17px;
    font-family: 'Sansita', sans-serif;

}

.sucursales__fotos .cedis{
    width: 1000px;
    height: 500px;
    border-radius: 20px;
    }


@media (max-width: 1030px) {
	.sucursales1{
		flex-direction: column;
		justify-content: center;
		align-items: center;
		height:auto;
        
	}
    .sucursales__fotos .cedis{
        width: 360px;
        height: 300px;
        border-radius: 20px;
    }
}


@media (max-width: 1260px) {
	.sucursales__fotos .cedis{
        width: 700px;
        height: 270px;
        border-radius: 20px;
        }
}

@media (max-width: 700px) {
    .sucursales__fotos .cedis{
        width: 500px;
        height: 270px;
        border-radius: 20px;
        }
}

@media (max-width: 500px) {
    .sucursales__fotos .cedis{
        width: 300px;
        height: 270px;
        border-radius: 20px;
        }
}
@media (max-width: 300px) {
    .sucursales__fotos .cedis{
        width: 250px;
        height: 270px;
        border-radius: 20px;
        }
}

