@import url('https://fonts.googleapis.com/css2?family=Concert+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kameron&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Belanosima&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Russo+One&display=swap');

.contenedorTransportes{
    background-color: #B590CA;
    height: 150%;
    padding-top: 20px;
    align-items: center;
    justify-content: center;
    position: relative;
    text-align: center;
}

.contenedorTransportes h1{
    text-align: center;
    font-size: 30px;
    color: #000000;
    font-family: 'Poppins', sans-serif;
}

.troques h1{
    padding-top: 20px;
    font-family: 'Kameron', serif;
    display: inline-block;
    position: relative;
    text-align: center;
    filter: drop-shadow(1px 8px 4px #2b2b2b);
    font-weight: bold;
}

.troques h1::after, .troques h1::before{
    content: '';
    position: absolute;
    width: 120px;
    height: 3px;
    background-color: currentColor;
    top: 1.2em;
}

.troques h1::before{
    left: -130px;
}
.troques h1::after{
    right: -130px;
}

.container__cards{
    max-width: 1200px;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-bottom: 20px;
}

.card3{
    width: 240px;
    margin: 10px;
    transition: all 300ms;

}
.card3:hover{
    width: 280px;

}

.modelos{
    width: 250px;
    height: 150px;
}
.modelos1{
    height: 150px;
    width: 200px;
}

.card3 .cover{
    position: relative;
    width: 100%;
    height: 140px;
    overflow: hidden;
}

.card3 .cover img{
    width: 280px;
    display: block;
    margin: auto;
    position: relative;
    z-index: 1;
    top: 10px;
    transition: all 400ms;
    
}
.card3:hover .cover img{
    top: 0px;
}

.card3 .img__back{
    width: 100%;
    height: 200px;
    position: absolute;
    bottom: -80px;
    left: 0;
    background-size: cover;
    border-radius: 20px;
    
}

.card3:nth-of-type(1) .img__back{
    background: radial-gradient(#ffffff, #515151fd);
}
.card3:nth-of-type(2) .img__back{
    background: radial-gradient(#f0f0f0, #515151fd);
}
.card3:nth-of-type(3) .img__back{
    background: radial-gradient(#ffffff, #515151fd);
}
.card3:nth-of-type(4) .img__back{
    background: radial-gradient(#ffffff, #515151fd);
}
.card3:nth-of-type(5) .img__back{
    background: radial-gradient(#ffffff, #515151fd);
}
.card3:nth-of-type(6) .img__back{
    background: radial-gradient(#ffffff, #515151fd);
}

.card3 .description{
    background: #ffffff;
    margin-top: -10px;
    padding: 20px;
    border-radius: 0px 0px 20px 20px;
    transition: all 300ms;
    box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.882);

}
.card3:hover .description{
    padding: 30px;
}


.card3 .description h2{
    margin-top: 2px;
    text-align: center;
    font-size: 22px;
}

.card3 .description p{
    margin-top: 2px;
}

@media (max-width: 375px){
    .troques h1::after, .troques h1::before{
        content: '';
        position: absolute;
        width: 100px;
        height: 3px;
        background-color: currentColor;
        top: 1.2em;
    }
    .troques h1::before{
        left: -110px;
    }
    .troques h1::after{
        right: -110px;
    }
}

@media (max-width: 325px){
    .troques h1::after, .troques h1::before{
        content: '';
        position: absolute;
        width: 90px;
        height: 3px;
        background-color: currentColor;
        top: 1.2em;
    }
    .troques h1::before{
        left: -100px;
    }
    .troques h1::after{
        right: -100px;
    }
}