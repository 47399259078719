.mapa{
    width: 1000px;
    height: 500px;
    border-radius: 20px;
}
.mapa1{
    width: 100%;
    height: 500px;
    border-radius: 20px;
    filter: drop-shadow(1px 1px 4px #00000080);
}

.contenedorMapa{
	background: linear-gradient(1deg,#7d1818,#014070);
    padding-bottom: 50px;
}

@media (max-width: 915px){
    .mapa{
        width: 700px;
        height: 360px;
    }    
}

@media (max-width: 820px){
    .mapa{
        width: 500px;
        height: 360px;
    }    
}

@media (max-width: 520px){
    .mapa{
        width: 400px;
        height: 360px;
    }    
}

@media (max-width: 415px){
    .mapa{
        width: 300px;
        height: 360px;
    }    
}

@media (max-width: 280px){
    .mapa{
        width: 250px;
        height: 360px;
    }  
}