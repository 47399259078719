/*NavBar*/
.navbar>.container{
  flex-direction: row !important;
}
.navbar-expand-lg .navbar-collapse{
  justify-content: flex-end;
}
.navbar{
  background: linear-gradient(100deg ,#0D47A1,#42A5F5,#0D47A1) !important;
  width: 100%;
  box-shadow: 0 0 3rem black;
}
.navbar-nav{
  align-items: flex-start !important;
}
.navbar-toggler{
   border: none !important;
}

.navbar-toggler:focus{
  box-shadow: none !important;
}
.icon1{
  color:  white;
}

.navbar-nav .nav-item{
  float: right !important;
}

.nav-item{
  position: relative;
}

.nav-item:hover{
  /*text-decoration: underline;*/
  border-bottom: 3px solid #ffffff;
  color:#fff!important;
}
.nav-item::after{
  opacity: 0;
  transition: all 0.2s;
  height: 2px;
  width: 100%;
  background-color: black;
  position: absolute;
  bottom: 0;
  left: 0;
}
.nav-item:hover::after{
  opacity: 1;
}

.rap{
  width: 200px;
  height: 48px;
  filter: drop-shadow(1px 1px 4px #ffffff)
}
.link{
  text-decoration: none;
  color: #ffffff96;
  padding-right: 15px;
}
.link:hover{
  text-decoration: none;
  color: white;
  padding-right: 15px;
}

@media screen and (max-width: 1685px) {
  .navbar-nav{
    padding-left: 80% !important ;
  }
  .rap{
    width: 190px;
    height: 45px;
  }
}

@media screen and (max-width: 1000px) {
  .navbar-nav{
    padding-left: 40% !important ;
  }
  .rap{
    width: 200px;
    height: 45px;
  }
}

@media screen and (max-width: 990px) {
  .navbar-nav{
    padding-left: 0 !important ;
  }
  .rap{
    width: 190px;
    height: 45px;
  }
  .nav-item:hover{
    /*text-decoration: underline;*/
    border-bottom: 3px solid #ffffff;
    width: 80px;
  }
}
/* NavBar Fin */

/* Pagina no encontrada */
.notfound{
  margin-top: 70px;
  
}
.collapse .navbar-collapse{
  display: flex;
}

/* Formulario */
.formulario{
  height: 94vh;
}